
import { defineComponent } from 'vue'
import { IonPage, IonContent, IonText, IonButton, IonIcon } from '@ionic/vue'
import PageHeader from '@/components/partial/PageHeader.vue'
import { starOutline } from 'ionicons/icons'

export default defineComponent ({
  name: 'ReadReview',

  components: { IonPage, IonContent, IonText, IonButton, IonIcon, PageHeader },

  setup () {
    return { starOutline }
  },

  data () {
    return {
      menu: {
        name: 'Sop Tulang Kuah Rawon',
        day: 'Rabu',
        date: '1 September 2021',
        portion: 10,
        img: 'https://lorempixel.com/128/80/food',
      },
      driver: {
        name: 'Putra',
        nopol: 'BP 12345 UY',
        img: 'https://lorempixel.com/80/80/people',
      }
    }
  }
})
